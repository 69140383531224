import Miners from "../../views/miners/Miners";
import User from "../../views/system/user/user";
import Role from "../../views/system/role/role";
import Plant from "../../views/remote_miner/plant/plant";
import Dashboard from "../../views/dashboard/Dashboard";
import RemoteMiner from "../../views/remote_miner/miners/miner";
import StandardMiner from "../../views/standard_miner/miners/miner";
import ProviderManage from "../../views/standard_miner/provider/provider";
import CouponManage from "../../views/coupon_seting/coupon/coupon";
import Menu from "../../views/system/menu/menu";
import SocketMiner from "../../views/socket_miner/miners/miner";
import PlantMiner from "../../views/plant_miner/miners/miner";
import Preorder from "../../views/preorder/message/preorder";
import ApiPlant from "../../views/api_plant/plants/plant";
import AkreAirdrop from "../../views/token/akreAirdrop/akre";
import HardwarePurch from "../../views/hardwarepurch/miners/miners"
import RemoteMinerQuality from "../../views/remote_miner/dataQuality/dataQuality";
import SocketMinerPlugs from "../../views/socket_miner/miners/plugs"

const router = [
    {
        path: "/",
        name: "Dashboard",
        component: <Dashboard />, 
        auth: false
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: <Dashboard />,
        auth: false
    },
    {
        path: "/miners",
        name: "Miners",
        component: <Miners />,
        auth: false
    },
    {
        path: "/user",
        name: "User Manage",
        component: <User />,
        auth: false
    },
    {
        path: "/role",
        name: "Role Manage",
        component: <Role />,
        auth: false
    },
    {
        path: "/menu",
        name: "Menu Manage",
        component: <Menu />,
        auth: false
    },
    {
        path: "/remote-miner/plant",
        name: "Plant Manage",
        component: <Plant />,
        auth: false
    },
    {
        path: "/remote-miner/miner",
        name: "Miner Manage",
        component: <RemoteMiner />,
        auth: false
    },
    {
        path: "/standard-miner/miner",
        name: "Miner Manage",
        component: <StandardMiner />,
        auth: false
    },
    {
        path: "/standard-miner/provider",
        name: "Provider Manage",
        component: <ProviderManage />,
        auth: false
    },
    {
        path: "/coupon-setting/coupon",
        name: "Coupon Manage",
        component: <CouponManage />,
        auth: false
    },
    {
        path: "/socket-miner/miner",
        name: "Miner Manage",
        component: <SocketMiner />,
        auth: false
    },
    {
        path: "/socket-miner/plug",
        name: "Plug Manage",
        component: <SocketMinerPlugs />,
        auth: false
    },
    {
        path: "/plant-miner/miner",
        name: "Miner Manage",
        component: <PlantMiner />,
        auth: false
    },
    {
        path: "/api-plant/plant",
        name: "Plant Manage",
        component: <ApiPlant />,
        auth: false
    },
    {
        path: "/token/akreAirdrop",
        name: "Akre Manage",
        component: <AkreAirdrop />,
        auth: false
    },
    {
        path: "/preorder",
        name: "Preorder",
        component: <Preorder />,
        auth: false
    },
    {
        path: "/hardwarepurch/miners",
        name: "Hardware Miners",
        component: <HardwarePurch />,
        auth: false
    },
    ,
    {
        path: "/remote-miner/dataQuality",
        name: "Data Quality",
        component: <RemoteMinerQuality />,
        auth: false
    },
]

export default router;
